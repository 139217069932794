

import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class AudioVolume extends Vue {

  @Prop({default: 0}) volume: number;

  getIcon() {

    if (this.volume >= 0.7) {
      return 'mdi-volume-high'
    } else if (this.volume >= 0.4) {
      return 'mdi-volume-medium'
    } else if (this.volume > 0.0) {
      return 'mdi-volume-low'
    } else {
      return 'mdi-volume-off'
    }
  }
}
