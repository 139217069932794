import httpResource from "@/components/infrastructure/http/httpResource";
import store from "@/stores/index";
import { AuthenticationGetterTypes, AuthenticationMutationTypes } from "@/stores/authentication/types";
import AuthUser from "@/components/domains/authentication/authUser";

export default class AuthenticationService {

  public static async login(accountId: string, password: string) {

    const data = {
      loginId: accountId,
      password: password
    };

    return (await httpResource.post("/auth/login", data));
  }

  public static async authMe() {

    const res = await httpResource.post("/auth/me");
    if (res.status === 200) {
      store.commit(AuthenticationMutationTypes.setUser, res.data);
      store.commit(AuthenticationMutationTypes.setAuthenticated, true);
    }

    return store.getters[AuthenticationGetterTypes.getUser];
  }

  public static async refreshToken() {
    return await httpResource.post("/auth/refresh");
  }

  public static isAuthenticated(): boolean {
    return store.getters[AuthenticationGetterTypes.isAuthenticated] as boolean;
  }

  public static async logout() {
    return httpResource.post("/auth/logout")
      .finally(() => {
        store.commit(AuthenticationMutationTypes.setUser, new AuthUser());
        store.commit(AuthenticationMutationTypes.setAuthenticated, false);
      });
  }

  public static async agreeEasilyLogin() {
    return httpResource.post(`/easily-login/agree?agree=${true}`);
  }

  public static async isAgreementEasilyLogin() {
    return httpResource.get(`/easily-login/agreement`);
  }

  public static async isEnabledEasilyLogin(authId: string) {
    return httpResource.get(`/easily-login/enabled?authId=${authId}`);
  }

  public static async easilyLogin(authId: string) {
    return httpResource.get(`/auth/easily-login-airumx?authId=${authId}`);
  }

  public static async enableEasilyLogin(enable: boolean, authId: string) {
    return httpResource.post(`/easily-login/enable-airumx?enabled=${enable}&authId=${authId}`);
  }
}
