import httpResource from "@/components/infrastructure/http/httpResource";

export default class EnterpriseService {

  public static async rooms() {
    return (await httpResource.get('/v2/enterprise/my/rooms')).data
  }

  public static async section(sectionId: string) {
    return (await httpResource.get(`/v2/enterprise/my/room/section?sectionId=${sectionId}`)).data
  }

  public static async reportSectionStatus(data: {
    playingDmvId: string;
    sectionId: string;
    actStopping: boolean;
    oldestStopTime: string;
  }) {
    return (await httpResource.post(`v2/enterprise/my/room/section/status/report`, data));
  }
}
