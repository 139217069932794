export default class DateTimeFormatter {

  public static formatStandard(date: Date) {
    const str = date.getFullYear()
      + '/' + ('0' + (date.getMonth() + 1)).slice(-2)
      + '/' + ('0' + date.getDate()).slice(-2)
      + ' ' + ('0' + date.getHours()).slice(-2)
      + ':' + ('0' + date.getMinutes()).slice(-2)
      + ':' + ('0' + date.getSeconds()).slice(-2);

    return str;
  }

  public static formatFromStr(str: any) {
    if (str === undefined) {
      return str;
    }
    return str
      .split("-")
      .join("/")
      .replace("T", " ")
      .split(".")[0];
  }
}
