import type AuthUser from "@/components/domains/authentication/authUser";

export interface AuthenticationState {
  authenticated: boolean;
  user: AuthUser;
}

export enum AuthenticationGetterTypes {
  getUser = 'AuthenticationGetterTypes' + '_GET_USER',
  isAuthenticated = 'AuthenticationGetterTypes' + '_IS_AUTHENTICATED'
}

export enum AuthenticationMutationTypes {
  setUser = 'AuthenticationMutationTypes' + '_SET_USER',
  setAuthenticated = 'AuthenticationMutationTypes' + '_SET_AUTHENTICATED'
}