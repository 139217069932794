import type { RootState } from "@/stores";
import type { Module } from "vuex";
import type { AuthenticationState } from "@/stores/authentication/types";
import getters from './getters'
import mutations from "@/stores/authentication/mutations";
import type AuthUser from "@/components/domains/authentication/authUser";

const state: AuthenticationState = {
  authenticated: false,
  user: {
    email: '',
    userId: '',
    username: ''
  } as AuthUser,
}

export const authentication: Module<AuthenticationState, RootState> = {
  namespaced: false,
  state,
  getters,
  mutations
}