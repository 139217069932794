
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AudioInfo } from "@/components/infrastructure/audio/audio";

@Component
export default class AudioPlayer extends Vue {
  @Prop({ type: Array as () => AudioInfo[], required: true, default: () => [] })
  readonly audioList!: AudioInfo[];

  @Prop({ type: Boolean as () => boolean, default: false })
  readonly loop!: boolean;

  private audioPlayer!: HTMLAudioElement;
  private selectedAudio: AudioInfo = this.audioList[0];

  private selectAudio(): void {
    this.audioPlayer.src = this.selectedAudio.src;
    this.$emit("selectAudio", this.selectedAudio, this.audioPlayer);
  }

  private play(): void {
    this.audioPlayer.play();
    this.$emit("play", this.selectedAudio, this.audioPlayer);
  }

  private pause(): void {
    this.audioPlayer.pause();
    this.$emit("pause", this.selectedAudio, this.audioPlayer);
  }

  private stop(): void {
    this.audioPlayer.pause();
    this.audioPlayer.currentTime = 0;
    this.$emit("stop", this.selectedAudio, this.audioPlayer);
  }

  private next(): void {
    const index = this.audioList.indexOf(this.selectedAudio);
    if (index < this.audioList.length - 1) {
      this.selectedAudio = this.audioList[index + 1];
      this.selectAudio();
    }
    this.$emit("next", this.selectedAudio, this.audioPlayer);
  }

  private prev(): void {
    const index = this.audioList.indexOf(this.selectedAudio);
    if (index > 0) {
      this.selectedAudio = this.audioList[index - 1];
      this.selectAudio();
    }
    this.$emit("prev", this.selectedAudio, this.audioPlayer);
  }

  private ended(): void {
    console.log("ended")
    this.$emit("ended", this.selectedAudio, this.audioPlayer);
    if (this.loop) {
      this.selectAudio();
    }
  }

  private playing(): void {
    this.$emit("playing", this.selectedAudio, this.audioPlayer);
  }

  private paused(): void {
    this.$emit("paused", this.selectedAudio, this.audioPlayer);
  }

  private timeUpdate(): void {
    this.$emit("timeupdate", this.selectedAudio, this.audioPlayer);
  }

  private changeLoop(): void {
    this.$emit("changeLoop", this.audioPlayer);
  }

  private mounted(): void {
    this.audioPlayer = this.$refs.audioPlayer as HTMLAudioElement;
    this.selectAudio();
  }
}
