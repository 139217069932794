import Vue from "vue";
import Vuex from "vuex";
import type { StoreOptions } from "vuex";
import {authentication} from "@/stores/authentication";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export interface RootState {
  version: string;
}

const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0"
  },
  modules: {
    authentication
  },
  plugins: [createPersistedState(
    {
      // ストレージの種類を指定。デフォルトではローカルストレージ
      storage: window.sessionStorage,
    }
  )]
};

export default new Vuex.Store<RootState>(store);

