

import {Component, Prop, PropSync, VModel, Vue} from "vue-property-decorator";
import DateTimeFormatter from "@/components/infrastructure/dateTimeFormatter";
import EnterpriseService from "@/components/domains/rooms/EnterpriseService";
import EnterpriseSection from "@/components/domains/rooms/dto/enterpriseSection";
import { AudioInfo } from "@/components/infrastructure/audio/audio";
import AudioPlayer from "@/components/infrastructure/audio/AudioPlayer.vue";
import AudioVolume from "@/components/infrastructure/audio/AudioVolume.vue";
import EnterpriseSectionManagement from "@/components/domains/rooms/dto/enterpriseSectionManagement";

@Component({
  components: {AudioVolume, AudioPlayer }
})
export default class UmxPlayer2 extends Vue {

  @VModel() section: EnterpriseSection;
  @PropSync("signal", { default: false }) syncSignal: boolean;

  // signal
  private badSignalCount = 0;

  // init
  private initialized = false;

  // playing
  private ctrlPlaying = false;
  private actPlaying = false;
  private currentTime = 0;

  // management
  private currentIndex = -1;
  private audioList: AudioInfo[] = [];
  private selectAudio: AudioInfo;
  private audioEl: HTMLAudioElement;
  private initVolume = 0;
  private ctrlVolume = 0.5;

  // reporting
  private latestReport = {};

  // updating
  private intervalSec = 15;
  private intervalId = -1;

  beforeMount() {
    this.currentIndex = -1;
    this.audioList = [];
    this.initialized = false;
  }

  mounted() {
    this.notification();
    if (this.audioEl) {
      this.audioEl.addEventListener('loadedmetadata', () => {
        this.initVolume = this.ctrlVolume;
        this.defineVolume();
      });
      this.audioEl.addEventListener('ended', () => {
        console.log('ended')
        this.notification();
      });
      this.audioEl.addEventListener('paused', () => {
        console.log('paused')
        this.notification();
      });
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList' && !this.audioEl.isConnected) {
            this.notification();
          }
        });
      });
      observer.observe(this.audioEl.parentNode!, { childList: true });
    }

    this.intervalId = setInterval(() => {
      this.notification();
    }, this.intervalSec * 1000);

    this.initialized = true;
  }

  destroyed() {
    clearInterval(this.intervalId);
    this.actPlaying = false;
    this.reportStatus();
  }

  private async notification() {
    this.updateCurrentMng().then(() => {
      this.check();
      this.reportStatus();
    })
  }

  private check() {

    if (!this.audioEl) {
      return;
    }

    if (this.ctrlPlaying) {
      this.audioEl.play();
    } else {
      this.audioEl.pause();
    }

    if (this.ctrlVolume >= 0) {
      this.adjustVolume(this.ctrlVolume);
    }

    this.actPlaying = !this.audioEl.paused && !this.audioEl.ended;
  }

  private play() {
    if (this.audioEl) {
      this.audioEl.volume = this.ctrlVolume;
      this.adjustVolume(this.ctrlVolume);
      this.audioEl.play();
      this.check();
    }
  }

  private selectedAudio(audioInfo: AudioInfo, el: HTMLAudioElement) {
    this.selectAudio = audioInfo;
    this.audioEl = el;
  }

  private timeupdate(audioInfo: AudioInfo, el: HTMLAudioElement) {
    this.currentTime = el.currentTime;
    if (this.currentTime <= 0.1) {
      this.defineVolume();
    }
  }

  private defineVolume() {
    this.audioEl.volume = this.initVolume;
  }

  // TODO 設計
  // private audioCtx!: AudioContext
  // private gainNode!: GainNode
  private adjustVolume(volumeLevel: number) {

    this.audioEl.volume = volumeLevel;

    // if (!this.audioCtx) {
    //   // AudioContextオブジェクトを作成
    //   this.audioCtx = new AudioContext();
    // }
    //
    // if (!this.gainNode) {
    //   // 現在再生中の音声に接続されているGainNodeオブジェクトを取得
    //   this.gainNode = this.audioCtx.createGain();
    //
    //   const audioSource = this.audioCtx.createMediaElementSource(this.audioEl);
    //   audioSource.connect(this.gainNode);
    //   this.gainNode.connect(this.audioCtx.destination);
    // }
    //
    // // 音量を設定
    // this.gainNode.gain.value = volumeLevel;
  }

  private async updateCurrentMng(afterFunc: Function = () => { /* NOP */ }) {

    // 再生指示のあるマネジメントを取得
    let stopCount = 0;

    EnterpriseService.section(this.section.sectionId).then((section) => {
      this.section = section;
      section.managements.forEach((m: EnterpriseSectionManagement, index: number) => {
        if (m.playing || (this.getCurrentMng !== null && this.getCurrentMng.id === m.id)) {
          this.currentIndex = index;
          this.ctrlPlaying = m.playing;
          this.ctrlVolume = m.volume >= 0 ? this.ctrlVolume = m.volume : 1;
          this.audioList.push({
            id: m.dmv.id,
            src: m.dmv.path,
            title: m.dmv.fileName,
          } as AudioInfo);
        } else {
          stopCount++;
        }
      });

      // すべてストップ制御の場合リセット
      if (stopCount == this.section.managements.length) {
        this.currentIndex = -1;
        this.ctrlPlaying = false;
      }
    })
      .finally(afterFunc())
  }

  reportStatus() {
    if (this.section) {

      // レポーティングデータの作成
      const data = {
        playingDmvId: this.actPlaying && this.getCurrentMng ?
          this.getCurrentMng.dmv.id : "",
        sectionId: this.section.sectionId,
        actStopping: !this.actPlaying,
        oldestStopTime: this.actPlaying ?
          DateTimeFormatter.formatStandard(new Date(1)) :
          DateTimeFormatter.formatStandard(new Date())
      };

      // レポーティング実施
      EnterpriseService.reportSectionStatus(data)
        .then((res) => {
          if (res.status !== 200) {
            if (this.badSignalCount > 3) {
              this.updateSignal(false);
            } else {
              this.badSignalCount++;
            }
          } else {
            this.badSignalCount = 0;
            this.updateSignal(true);
          }
        })
        .catch(() => {
          this.updateSignal(false);
        })
        .finally(() => {
          this.latestReport = data;
        });
    }
  }

  updateSignal(signal: boolean) {
    this.$emit('update:signal', signal);
  }

  getDisplayTime(audio: HTMLAudioElement) {
    return this.getTimeMinutesSeconds(audio.currentTime) + ' / ' + this.getTimeMinutesSeconds(audio.duration)
  }

  getTimeMinutesSeconds(audioTime: number) {
    const date = new Date(audioTime * 1000);
    const minutes = date.getMinutes().toString()
    const seconds = date.getSeconds().toString()
    return (minutes.length == 1 ? '0' : '') + minutes + ":" + (seconds.length == 1 ? '0' : '') + seconds
  }

  get isReady() {
    return this.currentIndex != -1;
  }

  get isRequirePlayingAction() {
    return !this.actPlaying && this.ctrlPlaying;
  }

  get isControlled() {
    return this.ctrlPlaying == this.actPlaying
  }

  get getCurrentMng() {
    if (this.currentIndex == -1) {
      return null;
    }
    return this.section.managements[this.currentIndex];
  }
}
