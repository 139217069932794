
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class CommonViewLayout extends Vue {
  name = "CommonViewLayout"
  @Prop() navLinks: [{ path: string; title: string }];

  mounted() {
    window.scrollTo(0, 0);
  }
}
