import { AudioInfo } from "@/components/infrastructure/audio/audio";

interface AudioData {
  id: string;
  buffer: AudioBuffer;
  loop: boolean;
  loopSt: number;
  loopEd: number;
}

type CallbackFunction = (data: any) => void;

export default class ApiWebAudioController {

  public initialized = false;
  public audioList: AudioInfo[];
  public gainNode: GainNode;
  private currentIndex = 0;
  private callbacks: Map<string, CallbackFunction> = new Map();
  private volume = 0.5;
  private isPlaying = false;
  private currentTime = 0;
  private readonly audioDataList: AudioData[];
  private context: AudioContext;
  private source: AudioBufferSourceNode;

  // Listener
  private timeupdateListener: (source: AudioBufferSourceNode) => void = () => {
    // NOP
  };

  constructor(audioList: AudioInfo[], volume: number) {
    this.audioList = audioList;
    this.context = new AudioContext();
    this.gainNode = this.context.createGain();
    this.gainNode.gain.value = volume;
    this.source = this.context.createBufferSource();
    this.audioDataList = [];
    this.loadAudioFiles().finally(() => {
      this.initialized = true;
    });
  }

  public async loadAudioFiles() {
    for (const audio of this.audioList) {
      const response = await fetch(audio.src,
        {
          mode: "cors",
        }
      );
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await this.context.decodeAudioData(arrayBuffer);
      this.audioDataList.push({
        id: audio.id,
        buffer: audioBuffer,
        loop: audio.loop,
        loopSt: 0,
        loopEd: audioBuffer.duration
      })
    }
  }

  public play(id?: string) {
    if (this.isPlaying) {
      this.stop();
    }
    if (id) {
      this.currentIndex = this.audioList.findIndex(audio => audio.id === id);
    }
    const audioData = this.audioDataList[this.currentIndex];
    this.source = this.context.createBufferSource();
    this.source.buffer = audioData.buffer;
    this.source.connect(this.gainNode);
    this.source.loop = true;
    this.source.loopEnd = audioData.loopEd;
    this.source.loopStart = audioData.loopSt;
    this.gainNode.connect(this.context.destination);
    this.source.start(0, this.currentTime);
    this.isPlaying = true;
  }

  public pause() {
    if (this.isPlaying) {
      this.source.stop();
      this.currentTime = this.context.currentTime % this.source.buffer!.duration;
      this.isPlaying = false;
    }
  }

  public stop(id?: string) {
    if (id !== undefined) {
      this.currentIndex = this.audioList.findIndex(audio => audio.id === id);
    }
    if (this.isPlaying) {
      this.source.stop();
      this.currentTime = 0;
      this.isPlaying = false;
    }
  }

  public toggleLoop(id?: string) {
    if (id !== undefined) {
      this.currentIndex = this.audioList.findIndex(audio => audio.id === id);
    }
    this.setLoop(!this.isLoop)
  }

  public playNext() {
    this.currentIndex = (this.currentIndex + 1) % this.audioList.length;
    this.play();
  }

  public playPrevious() {
    this.currentIndex = (this.currentIndex - 1 + this.audioList.length) % this.audioList.length;
    this.play();
  }

  public setVolume(volume: number) {
    this.volume = volume;
    this.gainNode.gain.value = volume;
  }

  public setLoop(loop: boolean) {
    this.getCurrentAudioData.loop = loop;
    this.source.loop = loop;
  }

  public getCurrentTime() {
    return this.currentTime;
  }

  public setCurrentTime(time: number) {
    this.currentTime = time;
    if (this.isPlaying) {
      this.pause();
      this.play();
    }
  }

  public setOnTimeUpdateCallback(callback: (source: AudioBufferSourceNode) => void) {
    this.timeupdateListener = callback;
  }


  public on(eventName: string, callback: CallbackFunction) {
    this.callbacks.set(eventName, callback);
  }

  public off(eventName: string) {
    this.callbacks.delete(eventName);
  }

  public get getVolume() {
    return this.volume;
  }

  public get isLoop() {
    return this.getCurrentAudioData.loop;
  }

  public get getCurrentPlayingTime() {
    return this.source.context.currentTime;
  }

  public get isPlayingNow() {
    return this.isPlaying;
  }

  public get getCurrentAudioData() {
    return this.audioDataList[this.currentIndex];
  }
}