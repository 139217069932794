import EnterpriseSectionManagement from "@/components/domains/rooms/dto/enterpriseSectionManagement";
import EnterpriseRoom from "@/components/domains/rooms/dto/enterpriseRoom";

export default class EnterpriseSection {
  public sectionId = "";
  public sectionName = "";
  public statusCode = "";
  public managements: EnterpriseSectionManagement[] = [];
  public room: EnterpriseRoom = new EnterpriseRoom();
}
