
import BackButton from "@/components/infrastructure/BackButton.vue";
import { Component, Vue } from "vue-property-decorator";
import EnterpriseService from "@/components/domains/rooms/EnterpriseService";
import EnterpriseSection from "@/components/domains/rooms/dto/enterpriseSection";
import router from "@/router";
import UmxPlayer2 from "@/components/domains/rooms/UmxPlayer2.vue";

@Component({
  components: { UmxPlayer2, BackButton }
})
export default class AirUmxSectionView extends Vue {

  paramSectionId = this.$route.params.id;

  section: EnterpriseSection = new EnterpriseSection();
  signal = false;

  mounted() {
    this.fetchSection();
  }

  fetchSection() {
    EnterpriseService.section(this.paramSectionId)
      .then((data) => {

        // 重複ログインチェック
        if (data.statusCode === "E1000") {
          alert("この区画はすでに利用されているため、ご利用いただけません。前の画面に戻ります。");
          router.push('/mypage/rooms')
        }

        // シグナル良好
        this.signal = true;
        // セクションを更新
        this.section = data;
      })
      .catch((error) => {
        this.signal = false;
      })
      .finally(() => {
        // NOP
      })

  }
}
