
import router from "@/router";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  methods: {
    router() {
      return router
    }
  }
})
export default class BackButton extends Vue {
  @Prop() title: string;
  @Prop() to: string;
}

