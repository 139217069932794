import type { AuthenticationState } from "@/stores/authentication/types";
import type { GetterTree } from "vuex";
import type { RootState } from "@/stores";
import { AuthenticationGetterTypes } from "@/stores/authentication/types";

export const getters: GetterTree<AuthenticationState, RootState> = {
  [AuthenticationGetterTypes.getUser]: (state: AuthenticationState) => {
    return state.user;
  },
  [AuthenticationGetterTypes.isAuthenticated]: (state: AuthenticationState) => {
    return state.authenticated;
  },
}

export default getters;