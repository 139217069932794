import type { AuthenticationState } from "@/stores/authentication/types";
import type { MutationTree } from "vuex";
import { AuthenticationMutationTypes } from "@/stores/authentication/types";
import type AuthUser from "@/components/domains/authentication/authUser";

const mutations: MutationTree<AuthenticationState> = {
  [AuthenticationMutationTypes.setUser]: (state, user: AuthUser) => {
    return state.user = user;
  },
  [AuthenticationMutationTypes.setAuthenticated]: (state, authenticated: boolean) => {
    return state.authenticated = authenticated;
  },
}

export default mutations;