
import { Component, Vue, Prop, Model, VModel } from "vue-property-decorator";
import { AudioInfo } from "@/components/infrastructure/audio/audio";
import ApiWebAudioController from "@/components/infrastructure/audio/ext/audioController";

@Component
export default class ApiWebAudioPlayer extends Vue {

  // @Prop({ type: Array, required: true })
  // private readonly audioList!: AudioInfo[]

  @VModel()
  private audioController!: ApiWebAudioController;

  private playAudio(audio: AudioInfo) {
    this.audioController.play(audio.id);
  }

  private stopAudio(audio: AudioInfo) {
    this.audioController.stop(audio.id);
  }

  private toggleLoop(audio: AudioInfo) {
    this.audioController.toggleLoop(audio.id);
  }
}
