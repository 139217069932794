import Vue from "vue";
// import { createPinia, PiniaVuePlugin } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./assets/main.css";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import store from '@/stores/index'
import '@mdi/font/css/materialdesignicons.css'

// Vue.use(PiniaVuePlugin);
Vue.use(Vuetify);

const vuetify = new Vuetify({
  iconfont: 'mdi',
  theme: {
    themes: {
      light: {
        primary: "#2196f3",
        secondary: "#03a9f4",
        accent: "#cddc39",
        error: "#ff5722",
        warning: "#ffeb3b",
        info: "#009688",
        success: "#00bcd4"
      }
    }
  }
});

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
