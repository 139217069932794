import { v4 as uuidv4 } from "uuid";

export default class UuidUtils {

  // ローカルストレージからUUIDを取得する関数
  public static getDeviceUUID(): string {
    const localStorageKey = "deviceUUID";
    let deviceUUID = localStorage.getItem(localStorageKey);

    if (deviceUUID === null) {
      // UUIDがまだローカルストレージに存在しない場合は生成し、保存する
      deviceUUID = uuidv4() as string;
      localStorage.setItem(localStorageKey, deviceUUID);
    }

    return deviceUUID;
  }
}