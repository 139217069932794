import { render, staticRenderFns } from "./UmxPlayer2.vue?vue&type=template&id=34cc1fd2&scoped=true&"
import script from "./UmxPlayer2.vue?vue&type=script&lang=ts&"
export * from "./UmxPlayer2.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34cc1fd2",
  null
  
)

export default component.exports